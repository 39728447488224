import React from 'react'
import styled from 'styled-components'
import { StyledH2, StyledPLight } from '../Shared'
import { media } from '../../helpers/media'

let Wrapper = styled('div')`
  svg {
    position: absolute;
    z-index: -5;
    left: 0;
  }
  margin-bottom: 8em;
  max-width: 500px;
  margin-top: -4em;

  ${media.tablet`
    margin-top: -2em;
    margin-bottom: 3em;
  `}

  ${media.smallphone`
    margin-top: 0em
  `}
`

export class About extends React.Component {
  render () {
    return (
      <Wrapper id='about'>
        <StyledH2 centered>About Me</StyledH2>

        <StyledPLight>I am a problem solver, striving to make every day better. My history illustrates my ability to use various learned tools to support, research, develop, evaluate and market a product or service. I work positively in a team environment with a diverse range of people. I have a work history of working to tight tolerances in tight deadlines. I enjoy working with human-centred technical and system based projects, taking satisfaction in making technology understandable and accessible to many, enriching our lives.
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>Why do I code?</span>
          <br />
          <br />I previously worked in design and construction. Starting in design and fabrication, I then moved to project management. However, I grew tired of the repetition and environment I was in. I was keen to find another way to apply my knowledge of turning an idea into a tangible product. I did a short course and then the 12 week intensive course in Web Development at General Assembly. After that I was hooked, solving problems in a digital way.</StyledPLight>
      </Wrapper>
    )
  }
}
