import React from 'react'
import styled from 'styled-components'
import { StyledH2 } from '../Shared'
import { AwesomeButton } from 'react-awesome-button'
import 'react-awesome-button/dist/styles.css'
import { theme } from '../../theme'
import { media } from '../../helpers/media'

let StyledForm = styled('form')`
  max-width: 85%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  margin-left: 0;
  margin-right: auto;

  h2 {
    margin-left: 1em;
  }

  button {
    margin-top: 2em;
  }

  ${media.tablet`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  `}
`

let StyledTextarea = styled('textarea')`
  grid-column: 1 / 3;
  grid-row: 2 /3;
  margin: 1em;
  font-weight: bold;
  border: none;
  box-shadow: unset;
  padding: 1em 1.4em;
  background-color: #d3d3d3;
  color: black;
  font-family: ${theme.fonts.secondary.family}, sans-serif;
  font-weight: ${theme.fonts.secondary.weight[1]};
  overflow: auto;
  resize: none;

  ${media.tablet`
    height: 120px;
  `}
`

let StyledInput = styled('input')`
  margin: 1em;
  padding: 0.4em 1.4em;
  height: 2em;
  font-weight: bold;
  border: none;
  box-shadow: unset;
  background-color: #d3d3d3;
  color: black;
  font-family: ${theme.fonts.secondary.family}, sans-serif;
  font-weight: ${theme.fonts.secondary.weight[1]};
`

let StyledButton = styled(AwesomeButton)`
  align-self: center;
  justify-self: center;
  grid-column: 1 / 3;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 280px;
`

let Wrapper = styled('div')`
  svg {
    position: absolute;
    z-index: -5;
    left: 0;
  }

  .aws-btn {
    --button-default-height: 48px;
    --button-default-font-size: 16px;
    --button-default-border-radius: 5px;
    --button-horizontal-padding: 28px;
    --button-raise-level: 7px;
    --button-hover-pressure: 1;
    --transform-speed: 0.175s;
    --button-primary-color: #177EAA;
    --button-primary-color-dark: #126488;
    --button-primary-color-light: #e4f7ff;
    --button-primary-color-hover: #2e8ab2;
    --button-primary-border: 1px solid #177EAA;
    --button-primary-color-active: #4597BB;
  }

  .aws-btn .aws-btn__content {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #edf1f3;
    &::before:hover{
      color: white;
    }
  }
`

export class ContactForm extends React.Component {
  render () {
    return (
      <div id='contact'>
        <StyledH2 centered>Contact</StyledH2>
        <Wrapper >
          <StyledForm
            name='contact'
            method='post'
            action='/?no-cache=1'
            data-netlify-honeypot='bot-field'
            data-netlify='true'>
             <input type='hidden' name='bot-field' />

             <input type="hidden" name="form-name" value="contact" />

            <StyledInput type='text' name='name' placeholder='Your name' style={{ gridColumn: '1 / 2' }} />

            <StyledInput type='email' name='email' placeholder='Your email' />

            <StyledTextarea name='message' placeholder='Your messgae' />

            <StyledButton type='primary'>
              Send
            </StyledButton>
          </StyledForm>
        </Wrapper>
      </div>
    )
  }
}
