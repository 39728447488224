/**
 * Image imports
 */

// Sample Images
// import sampleItem from '../../images/sample1.jpg'
// import sample1 from '../../images/sample1.jpg'
// import sample2 from '../../images/sample2.jpg'
// import sample3 from '../../images/sample3.jpg'
// import sample4 from '../../images/sample4.jpg'

// Bennie
import bennieFeaturedImage from '../../images/bennie-00-feature.png'
import bennie1 from '../../images/bennie-01-landing.png'
import bennie2 from '../../images/bennie-02-bankengine-select.png'
import bennie3 from '../../images/bennie-03-bankengine-auth.png'
import bennie4 from '../../images/bennie-04-your-bills.png'
import bennie5 from '../../images/bennie-05-add-bill.png'
import bennie6 from '../../images/bennie-06-review.png'
import bennie7 from '../../images/bennie-07-confirm.png'
import bennie8 from '../../images/bennie-08-your-plan.png'
import bennie9 from '../../images/bennie-09-chat.png'
import bennieScreenshots from '../../images/bennie-screenshots.jpg'

// Bright
// import brightHero from '../../images/bright_01.png'
import brightFeaturedImage from '../../images/bright-00-feature.png'
import bright1 from '../../images/bright-01-full-landing.png'
import bright2 from '../../images/bright-02-stakes.png'
import bright3 from '../../images/bright-03-coverdetail.png'
import bright4 from '../../images/bright-04-coverdetail.png'
// import bright5 from '../../images/bright-05-coverdetail.png'
// import bright6 from '../../images/bright-06-coverdetail.png'

// Networking
import networkingFeatureImage from '../../images/networking-00-feature.png'
import networking1 from '../../images/networking-01-marketing.png'
import networking2 from '../../images/networking-02-angularproject.png'
import networking3 from '../../images/networking-03-signup.png'
import networking4 from '../../images/networking-04-landing.png'
import networking5 from '../../images/networking-05-content.png'
import networking6 from '../../images/networking-06-esigningcontracts.png'
import networking7 from '../../images/networking-07-searchresults.png'
import networking8 from '../../images/networking-08-messaging.png'
import networking9 from '../../images/networking-09-analytics.png'

// AMPP Group
import amppFeatureImage from '../../images/amppgroup-feature.png'
import ampp1 from '../../images/amppgroup-content-small.png'
import ampp2 from '../../images/amppgroup-medium.png'
import ampp3 from '../../images/amppgroup-intercom.png'

// Goldielocks
import goldieFeatureImage from '../../images/goldielocks-05-feature.png'
import goldie1 from '../../images/goldielocks-01.png'
import goldie2 from '../../images/goldielocks-02.png'
import goldie3 from '../../images/goldielocks-03.png'
import goldie4 from '../../images/goldielocks-04.png'
import goldie6 from '../../images/goldielocks-06.png'

// Bloody
import bloodyFeatureImage from '../../images/bloody-01.png'

// Dancing Eric
import ericFeatureImage from '../../images/eric-01.png'
import eric2 from '../../images/eric-02-looped.gif'
import eric3 from '../../images/eric-03.png'
import eric4 from '../../images/eric-04.png'
import eric5 from '../../images/eric-05.png'

/**
 * What's what... How to add a project
 * 
 * title: Main project title
 * featureImage: Main image, used on main page
 * featureImageAltTxt: Main image alt text
 * theme.color: Hex or text code of the primary project color
 * theme.textColor: Hex or text code of the project, needs to contrast theme.color
 * slugName: a handmade slug of the project
 * shortDescription: short text project description
 * longDescription: more depth than shortDescription
 * projectUrl: [urls of the project]
 * images: images for the gallery 
 *    [{
 *       src: image source,
 *       description: 'description/caption of the image
 *    }]
 * fixedImages: images not part of the gallery but shown in the portfolio page on their own  
 *    [{
 *      src: image source,
 *      caption: string image caption
 *      description: string description
 *    }]
 * technologies: array of text strings of packages and tech used [String],
 * hideButton: boolean, shows a link on the main page to show more info about the project,
 * challenges: [Strings] 
 * 
 */


export let data = [
    // Animated Character
    {
      title: 'Dance Eric, Dance!',
      featureImage: ericFeatureImage,
      featureImageAltTxt: 'Animated Character',
      theme: {
        color: '#9606AE',
        txtColor: 'white'
      },
      slugName: 'threejs-character',
      shortDescription: 'Dance Eric, Dance! is a fun experiment with using 3D content in the browser with JavaScript. By using a model and then applying animations the click/touch and mouse events trigger different dance animations.',
      longDescription: `I came across a tutorial for an intro to Three.js, a lightweight, 3D library with a default WebGL renderer. I wanted to test out Three.js, I followed along then tweaked the model, design and converted it into a React component.

      Dance Eric! is a fun experiment with using 3D content in the browser with JavaScript. By using a model and then applying animations the click/touch and mouse movements are tracked and trigger different animations. Making Eric dance when you want him to. Mouse movement(touch location) is tracked and the head part of the model will follow, making it appear that Eric is always looking at the mouse. Click and Touch events are triggers to start an animation. Several animations are loaded and on each event, a random one is played.`,
      projectURL: [
        {
          title: 'Try the final React version',
          uri: 'https://condescending-neumann-ecb005.netlify.com/'
        },
        {
          title: 'Non-React prototype',
          uri: 'https://tender-chandrasekhar-754f74.netlify.com/'
        }
      ],
      images: [
        {
          src: eric5,
          description: 'Several animations are loaded'
        },
        {
          src: eric3,
          description: 'Including the chicken dance'
        },
        {
          src: eric4,
          description: 'Eric can really dance'
        },
      ],
      fixedImages: [{
        src: eric2,
        description: 'Mouse movement(touch location) is tracked and the head part of the model will follow, making it appear that Eric is always looking at the mouse.'
      }],
      technologies: ['yarn', 'React', 'styled componets', 'Three-js', 'Blender', 'Mixamo'],
      hideButton: false,
      challenges: ['My limited understanding of the type of 3D models being used in Three.js', 'The technical wording of aspects in the 3D space, converting wants into needs']
    },
    // Pocketful / Bennie
    { 
      title: 'Bennie',
      featureImage: bennieFeaturedImage,
      featureImageAltTxt: 'Bennie budget screen',
      theme: {
        color: '#7B00C3',
        txtColor: 'white'
      },
      slugName: 'project-bennie',
      shortDescription: `This money management web app project was created during New Zealand's first Open Banking Hackathon weekend organised by BankEngine. It was the prize winner for the Most Social Impact`,
      longDescription: `Bennie is a money management tool that helps 'NEETs' (15-17 year-old's that are Not Employed, in Education or Training) on the Youth Payment benefit) manage their recurring bill payments and provide financial education through ‘learning by doing’.`,
      projectURL: [
        { title: 'Demo',
          uri:'https://objective-clarke-aece7b.netlify.com'
        }, {
          title: 'Repo',
          uri: 'https://github.com/willcook4/pocketful_bankengine'
        }],
      images: [
        {
          src: bennie1,
          description: 'Landing page'
        }, {
          src: bennie2,
          description: 'BankEngine API integration'
        }, {
          src: bennie3,
          description: 'BankEngine Authentication'
        }, {
          src: bennie4,
          description: 'Summary of your bills'
        }, {
          src: bennie5,
          description: 'Add a bill'
        }, {
          src: bennie6,
          description: 'Review your payments'
        }, {
          src: bennie7,
          description: 'Confirm your payments'
        },  {
          src: bennie8,
          description: 'Your plan going forward'
        },  {
          src: bennie9,
          description: 'Live support chat'
        },
      ],
      fixedImages: [
        {
          src: bennieScreenshots,
          caption: 'User flow through the finished app',
          description: `Bennie uses customer transaction data to identify bill payments and income. Bennie analyses the bill data and converts the bill values into weekly payment instalments, which it aggregates into a single weekly bill payment taken to align with weekly benefit income [to prevent spending before bills]. Bennie uses income and bills data to show customers what their ‘disposable income’ is once their bills are taken care of. Consent from the customer to share bank data with bennie aligned with UK Open Banking model.`
        }
      ],
      technologies: ['npm', 'React', 'styled componets', 'Reach Router'],
      hideButton: false,
      challenges: ['Getting the project scoped for one and a half days work', 'Getting it done in time!']
    },

    // Bright
    {
      title: 'Bright Network',
      featureImage: brightFeaturedImage,
      featureImageAltTxt: 'Bright Network Screenshot of landing page',
      theme: {
        color: '#64e9ce'
      },
      slugName: 'project-bright',
      shortDescription: 'Bright Network (now Statys, https://statys.ai) uses an open protocol to connect financial services to the data economy. Through an open protocol and data marketplace powered by blockchain, the mission is to enable a new generation of financial services. Cryptographically-secured structures of user-owned public, private and meta-data. Data can be linked to establish a network reputation and trust.',
      longDescription:
      `Working as a Front-end developer on this project I worked in a multidisciplinary team with design, marketing and engineering. Building out the designers files into the working site. The site went through numerous major design changes and required fast-moving in a component-based setup. Initially starting as a fairly simple marketing page the site became a lot more complex as the project went on. With the addition of a logged-in user section to perform and record tasks for a Crypto bounty section, view a bounty leaderboard and to view and record marketing preferences and retrieve marketing material along with links to external content and blogging. This site was coupled with an in-house API (Typescript, Koa, Postgres DB).`,
      projectURL: [],
      images: [
        { 
          src: bright2,
          description: 'Leaderboard, content via an inhouse API'
        }, {
          src: bright3,
          description: 'Built from Sketch files to be pixel perfect',
        }, {
          src: bright4,
          description: 'Handling media from various sources'
        }
      ],
      fixedImages: [
        { 
          src: bright1,
          description: '',
          caption: 'Landing page',
      }],
      technologies: ['Yarn', 'React', 'Gatsby', 'Contentful', 'Styled-Components', 'Mixpanel', 'Zeplin'],
      hideButton: false,
      challenges: ['Moving through design iterations and pivots left us with unused code, it was important to keep the code clean']
    },
    
    // Networking
    {
      title: 'Networking Platforms',
      featureImage: networkingFeatureImage,
      featureImageAltTxt: 'Landing page',
      theme: {
        color: '#b1031f',
        txtColor: 'white'
      },
      slugName: 'project-networking',
      shortDescription: `The AMPP built B2B marketplaces change the way businesses connect in the emerging markets. Dense, high-value marketplaces that make finding your next sale or supplier easy. Technology built for business, designed for the emerging markets. Platforms that open access to new markets and optimise processes in existing ones.`,
      projectURL: [
        { 
          title: 'Trade SSA',
          uri: 'https://tradessa.org'
        }, {
          title: 'Trade Commonwealth',
          uri: 'https://tradecommonwealth.org'
        }, { 
          title: 'India UK Business Exchange',
          uri: 'https://indiauk.org'
        }, { 
          title: 'Maritime Connects',
          uri:'https://maritimeconnects.com'
        }],
      images: [
        {
          src: networking3,
          description: 'Simplified design and improved handling of form inputs'
        }, {
          src: networking4,
          description: 'Simpler interface improved uptake by users'
        }, {
          src: networking5,
          description: 'Improved ability for paying companies to provide richer content'
        }, { 
          src: networking2,
          description: 'Angular project I supported before rebuilding the API and building the new front-end'
        }, {
          src: networking6,
          description: 'Hellosign e-signing integration enabled faster turnaround for a signed contract for the sales team'
        }, {
          src: networking7,
          description: 'Improved search results with Elasticsearch'
        }, {
          src: networking8,
          description: 'On platform messaging enriched with email notifications'
        }, {
          src: networking9,
          description: 'Improved user control and visibility of analytics reduced the demand for support'
        }
      ],
      fixedImages: [
        { 
          src: networking1,
          caption: 'The Trade SSA Marketing page',
          description: `Working as a Full-stack developer. Initially managing a legacy Angular front-end and running multiple node and express backends on AWS whilst supporting other development tasks such as email marketing, Wordpress marketing sites and writing scripts for internal tasks. We have rewritten the stack and made many improvements for both the end-user and internal procedures using Node, Sequelize, Postgres, ElasticSearch, Sendgrid, Hellosign, React, Unlayer, Redux, Uppy and  Netlify to name a few.` 
        },
      ],
      technologies: ['Sketch', 'React', 'Node' ,'Angular', 'Hellosign', 'SendGrid', 'Elasticsearch', 'AWS S3', 'Intercom', 'Unlayer', 'Mixpanel', 'Redux', 'Styled Components', 'Express', 'Moment', 'JWT', '.csv file handling', 'Uppy', 'Netlify', 'Sequelize', 'PostgreSQL'],
      hideButton: false,
      challenges: ['Prioritising workflow to make the most impact across the board, whilst delivering meaningful improvements']
    }, 
    // AMPP Marketing
    {
      title: 'AMPP Group Marketing',
      featureImage: amppFeatureImage,
      featureImageAltTxt: 'AMPP Group marketing page',
      theme: {
        color: '#1074c4',
        txtColor: 'white'
      },
      slugName: 'project-ampp-marketing',
      shortDescription: 'AMPP Group marketing page was originally a Wordpress site. This was overhauled and rebuilt as a React & Contentful site at the same time as the rebuild of the networking platforms, as a team we were at capacity so we had a contractor scaffold the site from the designers files. Once the scaffold was done I made updates and changes over the next 18 months',
      projectURL: ['https://amppgroup.com'],
      images: [
        { 
          src: ampp3,
          description: 'Intercom integration for contact and support' 
        }, {
          src: ampp1,
          description: 'Content was managed via the Contentful API'
        }
      ],
      fixedImages: [
        {
          src: ampp2,
          caption: 'AMPP Group Marketing site - Landing page',
          description: `Design specification in Sketch files, svg/css animations, vimeo videos, image galleries, Contentful content management...`
        }
      ],
      technologies: ['Google Analytics', 'Contentful', 'Vimeo', 'Axios', 'React', 'React-slick', 'SCSS', 'Intercom', 'Google Fonts'],
      hideButton: false,
      challenges: ['Picking up the code after a contractor constucted the initial site, not setup in the same style we were using for other internal projects']
    },

    // Goldielocks
    {
      title: 'Goldielocks',
      featureImage: goldieFeatureImage,
      featureImageAltTxt: 'Goldielocks Web App Screenshot',
      theme: {
        color: '#da4531',
        txtColor: 'white' // '#1b5f5e'
      },
      slugName: 'project-goldielocks',
      shortDescription: 'Goldielocks was a personal project. Born from the idea to test the viability of a new fintech product. Would the product work as an investment? I constructed a backend that functioned as a data source. Updating the Firebase database numerous times a day through AWS lambda functions. The front-end was built using Google Firebase Authentication to provide a secure logged in dashboard where data analysis and custom svg charting provided feedback to test the investment potential',
      projectURL: [],
      images: [
        {
          src: goldie6,
          description: 'Login screen'
        }, {
          src: goldie2,
          description: 'Logged in state'
        }, {
          src: goldie1,
          description: 'Sample analysis of a point in time'
        }, {
          src: goldie3,
          description: 'Google Firebase used as the database.'
        }, {
          src: goldie4,
          description: 'AWS Lambda functions combined with scraping scripts kept the database updated with pricing'
        }
      ],
      technologies: ['AWS', 'Netlify', 'Google Cloud Platform', 'React', 'Node', 'Cheerio'],
      hideButton: false
    }, {
      title: 'Bloody Pressure',
      featureImage: bloodyFeatureImage,
      featureImageAltTxt: 'Bloody Pressure',
      theme: {
        color: 'red',
        txtColor: 'white'
      },
      slugName: 'project-bloody-pressure',
      shortDescription: 'Microsite to log the data provided and to provide graphing and simple trend analysis',
      projectURL: [],
      images: [],
      fixedImages: [{
        src: bloodyFeatureImage,
        caption: '',
        description: ''
      }],
      technologies: ['Google Firebase', 'React', 'VictoryChart', 'Moment', 'node', 'papaParse'],
      hideButton: true,
      challenges: ['Working with different datasets, time manipulation for standardisation']
    },
    // GA Projects
    {
      title: 'GA Bootcamp projects',
      featureImage: bloodyFeatureImage,
      featureImageAltTxt:'Coming soon',
      theme: {
        color: 'black',
        txtColor: 'whitesmoke'
      },
      slugName: 'project-ga',
      shortDescription: 'Projects made during my Web Development Immersive bootcamp',
      projectURL:[],
      images:[],
      fixedImages:[],
      technologies:[],
      hideButton: true
    }

  ]