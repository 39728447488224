import React from 'react'
// import styled from 'styled-components'
import { theme } from '../../theme'
// import { media } from '../../helpers/media'

export class Github extends React.Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='96.99' height='94.595' viewBox='0 0 96.99 94.595 '>
        <path fillRule='evenodd'
          clipRule='evenodd'
          fill={theme.colors.primary}
          d='M48.492 3.327c-24.894 0-45.081 20.184-45.081 45.086 0 19.917 12.917 36.815 30.833 42.777 2.254.413 3.077-.978 3.077-2.173 0-1.07-.039-3.905-.061-7.668-12.541 2.724-15.187-6.043-15.187-6.043-2.05-5.208-5.006-6.595-5.006-6.595-4.093-2.797.31-2.741.31-2.741 4.524.319 6.906 4.646 6.906 4.646 4.022 6.889 10.552 4.9 13.121 3.746.41-2.913 1.574-4.9 2.862-6.027-10.011-1.138-20.536-5.006-20.536-22.282 0-4.922 1.757-8.946 4.641-12.098-.464-1.141-2.011-5.724.442-11.931 0 0 3.785-1.212 12.398 4.622 3.596-1 7.453-1.5 11.287-1.517 3.828.017 7.685.518 11.287 1.517 8.607-5.834 12.385-4.622 12.385-4.622 2.46 6.207.912 10.791.449 11.931 2.889 3.152 4.634 7.176 4.634 12.098 0 17.319-10.541 21.13-20.584 22.247 1.619 1.392 3.061 4.143 3.061 8.349 0 6.027-.055 10.89-.055 12.368 0 1.206.812 2.608 3.1 2.168 17.899-5.973 30.805-22.86 30.805-42.772-.001-24.901-20.188-45.086-45.088-45.086z' />
      </svg>)
  }
}

export class Linkedin extends React.Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 430.117 430.117' width='96.99' height='94.595'>
        <path d='M430.117 261.543V420.56h-92.188V272.193c0-37.271-13.334-62.707-46.703-62.707-25.473 0-40.632 17.142-47.301 33.724-2.432 5.928-3.058 14.179-3.058 22.477V420.56h-92.219s1.242-251.285 0-277.32h92.21v39.309c-.187.294-.43.611-.606.896h.606v-.896c12.251-18.869 34.13-45.824 83.102-45.824 60.673-.001 106.157 39.636 106.157 124.818zM52.183 9.558C20.635 9.558 0 30.251 0 57.463c0 26.619 20.038 47.94 50.959 47.94h.616c32.159 0 52.159-21.317 52.159-47.94-.606-27.212-20-47.905-51.551-47.905zM5.477 420.56h92.184V143.24H5.477v277.32z' fill={theme.colors.primary} />
      </svg>
    )
  }
}

export class Email extends React.Component {
  render () {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 128' width='96.99' height='94.595'>
        <g>
          <rect height='72' width='96' x='16' y='40' fill={theme.colors.shade} />
          <polygon points='112 40 70.86 70.86 68.07 68.07 112 40' />
          <polygon points='16 40 57.14 70.86 59.93 68.07 16 40' fill='blue' stroke='2px' />
          <rect height='72' transform='translate(128 0) rotate(90)' width='96' x='16' y='28' fill={theme.colors.lightGrey} strokeWidth={2} stroke={theme.colors.darkGrey} />
          <polygon points='100 47.67 68.07 68.07 70.86 70.86 100 49 100 47.67' fill={theme.colors.highlight} />
          <polygon points='28 47.67 59.93 68.07 57.14 70.86 28 49 28 47.67' fill={theme.colors.highlight} />
          <polygon points='112 112 16 112 16 40 112 112' fill={theme.colors.primary} />
          <polygon points='16 112 112 112 112 40 16 112' fill={theme.colors.primary} />
          <polygon points='112 112 16 112 64 64 112 112' fill={theme.colors.primary} />
          <polygon points='112 112 73.33 69 70.86 70.86 112 112' fill={theme.colors.highlight} />
          <polygon points='16 112 54.67 69 57.14 70.86 16 112' fill={theme.colors.highlight} />
        </g>
      </svg>
    )
  }
}
