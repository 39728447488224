import React from 'react'
import { Blob } from '../Blob'
import styled from 'styled-components'
import Modal from 'react-modal'
import { StyledH2, StyledH3, StyledPLight, ActionBtn, LinkText } from '../Shared'
import { media } from '../../helpers/media'
import { Carousel } from '../Carousel'

let Wrapper = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    order: ${props => props.alignment === 'right' ? '1' : '0'};
  }
  &:hover {
    cursor: ${props => props.clickable ? 'pointer' : 'default'};
  }
  ${media.tablet`
    flex-direction: column;
    img {
      order: 0;
    }
  `}
`

let Title = styled(StyledH3)`
  border-bottom: 2px solid;
  border-bottom-color: ${props => props.themeColor ? props.themeColor : 'red'};
  width: fit-content;
`

let Details = styled('div')`
  margin: ${props => props.alignment === 'right' ? '-2.5em 2em 0 0' : '-2.5em 0 0 2em;'};

  ${media.tablet`
    margin: 0.4em 0 0 2em;
  `}
`

let OuterWrapper = styled('div')`
  margin-top: 4em;
  margin-bottom: 10em;
  width: 100%;
  position: relative;

  ${media.tablet`
    .blobs {
      left: 20%;
    }
    margin-bottom: 4em;
  `}
`

let ModalImage = styled('img')`
  max-width: 600px;

  ${media.tablet`
    margin-left: -20px;
  `}
  ${media.phone`
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  `}
`

let ModalContents = styled('div')`
  h3 {
    font-size: medium;
  }

  ${media.phone` 
    transform: scale(0.8);
    // margin-top: -160px;
    display: contents;
  `}
`

let ModalClose = styled(StyledH2)`
  position: fixed;
  right: 70px;
  top: 40px;
  display: inline-block;
  color: ${props => props.color ? props.color : 'inherit' };
  &:hover {
    text-shadow: 0px 0px 20px rgba(150, 150, 150, 0.67);
  }
  ${media.phone`
    right: 30px;
    top: 4px;
  `}
`

let ModalTitle = styled(StyledH2)`
  display: inline-block;
  margin-top: 24px;
`

let FixedImageCaption = styled('p')`
  color: #626262;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  max-width: 500px;
  border-bottom: 1px solid ${props => props.themeColor ? props.themeColor : 'red'};
  width: fit-content;
`

let PorfolioLinkText = styled(LinkText)`
  text-overflow: ellipsis;
  overflow: hidden;
`

let PorfolioLinkP = styled('p')`
  color: #626262;
  font-family: 'Roboto' ,sans-serif;
  font-weight: 400;
  white-space: nowrap;

  ${media.phone`
    text-align: center;
    max-width: 280px; // forces ellipis
  `}
  
`

export class PortfolioItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false
    }
    this.hideModal = this.hideModal.bind(this)
  }

  showModal (slugName) {
    this.setState({ showModal: true })
  }

  hideModal () {
    this.setState({ showModal: false })
  }

  render () {
    // Fixed images
    let images = null
    if (this.props.fixedImages && this.props.fixedImages.length > 0) {
      images = this.props.fixedImages.map((image, index) => {
        return (
          <div key={`img-${index}`}>
            <ModalImage src={image.src} alt={image.description} />
            <FixedImageCaption themeColor={this.props.theme.color}>{image.caption}</FixedImageCaption>
            <p style={{color: '#626262', fontFamily: `'Roboto' ,sans-serif`, fontWeight: '400', maxWidth: '500px'}}>{image.description}</p>
          </div>
        )
      })
    }

    let technology = null
    if (this.props.technologies && this.props.technologies.length > 0) {
      let technologyLi = this.props.technologies.map((item, index) => {
        return (
          <li key={`tech-${index}`}>
            <p style={{ fontFamily: `Roboto, sans-serif`, fontWeight: '400', color: '#626262'}}>{item}</p>
          </li>
        )
      })
      technology = (
        <>
          <StyledH3 style={{ display: 'block'}}>Main technology used:</StyledH3>
          <ul>
            {technologyLi}
          </ul>
        </>
      )
    }

    let projectUrl = null
    if (this.props.projectUrl && this.props.projectUrl.length > 0) {
      projectUrl = this.props.projectUrl.map((item, index) => {
        return (
          <PorfolioLinkP
            key={`urls-${index}`}>
            {item.title}
            <PorfolioLinkText
              type='block'
              themeColor={this.props.theme.color}
              key={`${index}-url`}
              href={item.uri} target='__blank'>{item.uri}
            </PorfolioLinkText>
          </PorfolioLinkP>
        )
      })
    }

    let challenges = null
    if (this.props.challenges && this.props.challenges.length > 0) {
      let challengesLi = this.props.challenges.map((item, index) => {
        return (
          <li key={`challenge-${index}`}>
            <p style={{color: '#626262', fontFamily: `'Roboto' ,sans-serif`, fontWeight: '400', maxWidth: '500px'}}>{item}</p>
          </li>
        )
      })
      challenges = (<>
        <StyledH3 style={{ display: 'block'}}>Biggest challenges:</StyledH3>
        <ul>{challengesLi}</ul>
        </>
      )
    }

    let gallery = null
    if (this.props.images && this.props.images.length > 0) {
      gallery = (
        <>
          <StyledH3 style={{ display: 'block'}}>Gallery</StyledH3>
          <Carousel
            projectMainColor={this.props.theme.color}
            txtColor={this.props.theme.txtColor}
            images={this.props.images} />
        </>
      )
    }

    return (
      <React.Fragment>
        <OuterWrapper>
          <Wrapper
            clickable={this.props.hideButton ? false : true}
            alignment={this.props.alignment}
            onClick={() => this.props.hideButton ? null : this.showModal(this.props.slugName)}>
            {this.props.imgSrc ? (<img src={this.props.imgSrc} alt={this.props.imgAltText} style={{ maxWidth: '300px', border: `2px solid ${this.props.theme.color}`, marginBottom: '14px' }} />) : null}

            <Details alignment={this.props.alignment}>
              <Title themeColor={this.props.theme.color}>{this.props.title}</Title>
              <StyledPLight>
                {this.props.shortDescription}
              </StyledPLight>
              {this.props.hideButton ? null : (
                <ActionBtn
                themeColor={this.props.theme.color}>
                <span>More info</span>
              </ActionBtn>
              )}

            </Details>
          </Wrapper>

          <Blob color={this.props.theme.color} alignment={this.props.alignment} />
        </OuterWrapper>

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.hideModal}
          style={{
            content: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: 'none',
              borderTop: `1em solid ${this.props.theme.color}`,
              borderBottom: `0.1em solid ${this.props.theme.color}`
            },
            overlay: {
              background: 'lightgrey'
            }
          }}
        >
          <ModalContents>
            <ModalTitle style={{marginBottom: '0'}}>{this.props.title}</ModalTitle>
            <ModalClose color={this.props.theme.color} onClick={this.hideModal}>X</ModalClose>
            {projectUrl}

            <StyledPLight style={{ maxWidth: '500px' }}>{this.props.shortDescription}</StyledPLight>
            <br />
            
            {/* Fixed images */}
            {images}

            {gallery}

            <StyledPLight style={{ maxWidth: '500px' }}>{this.props.longDescription}</StyledPLight>

            {technology}

            {challenges}
          </ModalContents>
        </Modal>
      </React.Fragment>
    )
  }
}
