import React from 'react'
import styled from 'styled-components'
import { media } from '../helpers/media'

const Blobs = styled.div`
  // .blobs
  position: absolute;
  z-index: -1;
  top: ${props => props.alignment === 'right' ? '-50px' : '-45px'};
  left: ${props => props.alignment === 'right' ? '55%' : '20%'};
  ${media.phone`
    left: calc(50% * -1);
  `}
`

const Shape = styled.div`
  // .blob {
  position: absolute;
  background: #edf1f3;

  &:nth-child(1) {
    top: -10px;
    left: -10px;
    width: 280px;
    height: 260px;
    border-radius: 100%;
    animation: mytopdown linear 10s infinite;
    // border: ${props => props.color ? `1px solid ${props.color}` : 'none'};
  }

  &:nth-child(2) {
    top: -10px;
    left: -10px;
    width: 250px;
    height: 280px;
    border-radius: 100%;
    animation: mydowntop linear 10s infinite;
  }

  &:nth-child(3) {
    top: 10px;
    left: -10px;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    animation: mytopleft linear 15s infinite;
  }

  &:nth-child(4) {
    top: -10px;
    left: 10px;
    border-radius: 45%;
    width: 240px;
    height: 250px;
    animation: mydownright linear 8s infinite;
  }

  &:nth-child(5) {
    top: -10px;
    left: -10px;
    width: 240px;
    height: 250px;
    border-radius: 42%;
    animation: mytopright linear 9s infinite;
  }

  &:nth-child(6) {
    top: -10px;
    left: -10px;
    width: 240px;
    height: 250px;
    border-radius: 47%;
    animation: mydownleft linear 30s infinite;
  }

  /* Keyframes */
  // Pull the element down and enlarge
  @keyframes mytopdown {
    0% {
      top: 0px;
      transform: rotate(0deg) scale(1);
    }
    50% {
      top: 40px;
      transform: rotate(180deg) scale(1.04);
    }
    100% {
      top: 0px;
      transform: rotate(360deg) scale(1);
    }
  }

  // Pull the element down
  @keyframes mydowntop {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    50% {
      top: -10px;
      transform: rotate(-180deg);
    }
    100% {
      top: 0px;
      transform: rotate(-360deg);
    }
  }


  // Push the element right and down
  @keyframes mytopleft {
    0% {
      left: 0px;
      top: 0px;
      transform: rotate(0deg);
    }
    50% {
      left: 50px;
      top: 50px;
      transform: rotate(180deg);
    }
    100% {
      left: 0px;
      top: 0px;
      transform: rotate(360deg);
    }
  }

  // Pull the element left and lift up towards page top
  @keyframes mydownright {
    0%   {
      left: 0px;
      top: 0px;
      transform: rotate(0deg);
    }
    50% {
      left: -10px;
      top: -5px;
      transform: rotate(180deg);
    }
    100% {
      left: 0px;
      top: 0px;
      transform: rotate(360deg);
    }
  }

  // move the element left and down
  @keyframes mytopright {
    0% {
      left: 0px;
      top: 0px;
      transform: rotate(0deg);
    }
    50% {
      left: -10px;
      top: 5px;
      transform: rotate(180deg);
    }
    100% {
      left: 0px;
      top: 0px;
      transform: rotate(360deg);
    }
  }

  // Pull the element down and right
  @keyframes mydownleft {
    0% {
      left: 0px;
      top: 0px;
      transform: rotate(0deg);
    }
    50% {
      left: 10px;
      top: 10px;
      transform: rotate(-180deg);
    }
    100% {
      left: 0px;
      top: 0px;
      transform: rotate(-360deg);
    }
  }
`

export class Blob extends React.Component {
  _getRandomIntInclusive (min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
    // The maximum is inclusive and the minimum is inclusive
  }

  render () {
    let blobCount = 6
    let BlobJSX = []
    let count = 0.8
    /* Generate x number of blobs */
    for (var i = 1; i <= blobCount; i++) {
      BlobJSX.push(<Shape style={{opacity: (count > 1) ? 1 : count}} id={`shape-${i}`} key={`shape-${i}`} color={this.props.color} />)

      count = count - 0.1
      count = (count < 0.1) ? 0.8 : count
    }

    return (
      <Blobs alignment={this.props.alignment} style={this.props.styleOverride} className='blobs'>
        {BlobJSX}
      </Blobs>
    )
  }
}
