import React from 'react'
// import { Link } from 'gatsby'
import { About } from '../components/About'
import { Portfolio } from '../components/Portfolio'
import { ContactForm } from '../components/ContactForm'
import { SocialLinks } from '../components/SocialLinks'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <About />
    <Portfolio />
    <ContactForm />
    <SocialLinks />
    {/* <Link to='/page-2/'>Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
