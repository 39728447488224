import React from 'react'
import { Blob } from '../Blob'
import styled from 'styled-components'
import { StyledH3 } from '../Shared'
import { media } from '../../helpers/media'

let Wrapper = styled('div')`
  flex-direction: ${props => props.direction === 'column' ? 'column' : 'row'};
  display: flex;
  align-items: ${props => props.alignment === 'start' ? 'start' : 'center'};
  justify-content: center;
  svg {
    width: 55px;
    height: 55px;
    margin-top: 0.8em;
    path {
      stroke: #177EAA;
    }
  }
  &:hover {
    h3 {
      color: darkgrey;
    }
    svg {
      path {
        fill: #177EAA;
      }
    }
  }

  ${media.tablet`
    svg {
      width: 40px;
      height: 40px;
    }
    margin-top: 0;
    margin-bottom: 2em;
  `}
`

let StyledLink = styled('a')`
  text-decoration: none;
  min-width: 135px;
  margin-left: 2.5em;
  margin-right: 2.5em;

  ${media.tablet`
    margin: 0;
  `}
`

let LinkText = styled(StyledH3)`
  color: #177EAA;
  ${media.tablet`
    margin-top: -1em;
  `}
`

let StyledDiv = styled('div')`
  position: relative;
  .blobs {
    transform: scale(0.55) translate(88px, 35px);
  }

  ${media.tablet`
    position: absolute;
    div {
      display: none;
    }
  `}
`

export class SocialItem extends React.Component {
  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
  }

  render () {
    return (
      <StyledLink href={this.props.link} target={this.props.target} rel='noopener'>
        <Wrapper direction='column'>
          <Wrapper alignment='start'>
            {this.props.imgSrc}
            <StyledDiv>
              <Blob styleOverride={{
                top: '-34px',
                left: '-146px',
                transform: `scale(0.8) rotate(${this.getRandomIntInclusive(0, 360)})` }} />
            </StyledDiv>
          </Wrapper>
          <LinkText>{this.props.linkText}</LinkText>
        </Wrapper>
      </StyledLink>
    )
  }
}
