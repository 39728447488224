import React from 'react'
import { SocialItem } from './SocialItem'
import styled from 'styled-components'
import { StyledH2 } from '../Shared'
import { media } from '../../helpers/media'

// Images
// import github from '../../images/github.svg'
import { Github, Linkedin, Email } from './socialSVGs'

let Wrapper = styled('div')`
  padding-top: 4em;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${media.tablet`
    padding-top: 0;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2em;
  `}
`

let OuterWrapper = styled('div')`
    height: 400px;
    margin-top: 8em;

    ${media.tablet`
      margin-top: 5em;
    `}
`

export class SocialLinks extends React.Component {
  render () {
    return (
      <OuterWrapper id='links'>
        <StyledH2 centered>Links</StyledH2>
        <Wrapper>
          <SocialItem
            link='https://github.com/willcook4'
            imgSrc={(<Github />)}
            imgAltText='Github'
            linkText='Github'
            target='_blank'
          />

          <SocialItem
            link='https://www.linkedin.com/in/will-cook'
            imgSrc={(<Linkedin />)}
            imgAltText='Linkedin'
            linkText='Linkedin'
            target='_blank'
          />

          <SocialItem
            link='mailto:hello@doubleucook.com'
            imgSrc={(<Email />)}
            imgAltText='Email'
            linkText='Email'
            target='_blank'
          />
        </Wrapper>
      </OuterWrapper>
    )
  }
}
