import React from 'react'
import { PortfolioItem } from './PortfolioItem'
import styled from 'styled-components'
import { StyledH2 } from '../Shared'
import { data } from './data'

let Wrapper = styled('div')`
  position: relative;
`

export class Portfolio extends React.Component {
  render () {
    return (
      <Wrapper id='portfolio'>
        <StyledH2 centered>Selected Projects</StyledH2>

        {data.map((project, index) => {
          return (
            <PortfolioItem
              key={`${project.slugName}-${index}`}
              title={project.title}
              imgSrc={project.featureImage}
              imgAltText={project.featureImageAltTxt}
              theme={project.theme}
              slugName={project.slugName}
              shortDescription={project.shortDescription}
              longDescription={project.longDescription}
              projectUrl={project.projectURL}
              images={project.images}
              fixedImages={project.fixedImages}
              technologies={project.technologies}
              challenges={project.challenges}
              alignment={index % 2 === 0 ? 'left' : 'right'}
              hideButton={project.hideButton ? true : false}
            />
          )
        })}
      </Wrapper>
    )
  }
}
