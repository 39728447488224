import React from "react"
import AwesomeSlider from 'react-awesome-slider'
import styled from 'styled-components'
import {default as ImageModal} from 'react-modal'
import AwsSliderStyles from 'react-awesome-slider/src/styles.js'
import { theme } from '../../theme'
import { StyledH3 } from '../Shared'

let ZoomIn = (props) => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="zoom-in"
    width="24px"
    height="24px"
    style={{marginBottom: "-6px", marginLeft: "12px"}}
    fill={props.txtColor || theme.colors.darkGrey}
    aria-hidden="true">
      <path d="M637 443H519V309c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v134H325c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h118v134c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V519h118c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zm284 424L775 721c122.1-148.9 113.6-369.5-26-509-148-148.1-388.4-148.1-537 0-148.1 148.6-148.1 389 0 537 139.5 139.6 360.1 148.1 509 26l146 146c3.2 2.8 8.3 2.8 11 0l43-43c2.8-2.7 2.8-7.8 0-11zM696 696c-118.8 118.7-311.2 118.7-430 0-118.7-118.8-118.7-311.2 0-430 118.8-118.7 311.2-118.7 430 0 118.7 118.8 118.7 311.2 0 430z">
      </path>
  </svg>
)

let ZoomOut = (
  <svg
    viewBox="64 64 896 896"
    focusable="false" 
    data-icon="zoom-out"
    width="24px"
    height="24px"
    style={{marginBottom: "-6px", marginLeft: "12px", marginRight: "12px"}}
    fill={theme.colors.darkGrey}
    aria-hidden="true">
      <path d="M637 443H325c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h312c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8zm284 424L775 721c122.1-148.9 113.6-369.5-26-509-148-148.1-388.4-148.1-537 0-148.1 148.6-148.1 389 0 537 139.5 139.6 360.1 148.1 509 26l146 146c3.2 2.8 8.3 2.8 11 0l43-43c2.8-2.7 2.8-7.8 0-11zM696 696c-118.8 118.7-311.2 118.7-430 0-118.7-118.8-118.7-311.2 0-430 118.8-118.7 311.2-118.7 430 0 118.7 118.8 118.7 311.2 0 430z">
      </path>
  </svg>
)

let Close = (
  <p style={{position: 'fixed', right: '66px', fontFamily: theme.fonts.primary.family + ', sans-serif'}}> <span>{ZoomOut}</span> Close</p>
)

const ImageModalWrapper = styled('div')`
  * {
    margin: 0;
    padding: 0;
  }
  
  display: grid;
  height: 100%;
  
  img {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
  }
  h2 {
    color: ${props => props.txtColor ? props.txtColor : theme.colors.darkGrey};
    font-family: ${theme.fonts.primary.family}, sans-serif;
  }
`

const Caption = styled('div')`
  background-color: ${props => props.bgColor ? props.bgColor : 'whitesmoke'};
  border-bottom: 3px solid ${props => props.bgColor ? props.bgColor : 'whitesmoke'};
  padding: 1px 30px;
  line-height: 10px;
  opacity: 0.8;
  bottom: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  p {
    color: ${props => props.txtColor ? props.txtColor : theme.colors.darkGrey};
    font-family: ${theme.fonts.primary.family}, sans-serif;
    text-align: center;
  }
`

const Wrapper = styled('div')`
  padding-bottom: 45px;
  width: 110%;
  // margin-left: -1.5em;

  // // imported slider overides
  .aws-sld__content {
    align-items: flex-end;
  }

  .aws-sld {
    --loader-bar-color: ${props => props.projectMainColor ? props.projectMainColor : 'lightgrey'};
    --control-bullet-color: ${props => props.projectMainColor ? props.projectMainColor : 'lightgrey'};
    --control-bullet-active-color: lightgray;
    --content-background-color: white;
    --organic-arrow-color: ${props => props.projectMainColor ? props.projectMainColor : 'lightgrey'};
  }

  .awssld__content > img,
  .aws-sld__content > img {
    object-fit: contain;
  }
`

let CaptionText = styled(StyledH3)`
  text-align: center;
  margin-top: 0.5em;
`
 
export class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showEnlargedImg: false,
      showImg: null
    }

    this.openImgModal = this.openImgModal.bind(this);
    this.closeImgModal = this.closeImgModal.bind(this);
  }

  openImgModal(itemIndex) {
    this.setState({
      showEnlargedImg: true,
      showImg: itemIndex
    })
  }

  closeImgModal() {
    this.setState({
      showEnlargedImg: false,
      showImg: null
    });
  }
  

  render() {
    return (
      <Wrapper projectMainColor={this.props.projectMainColor}>
        <ImageModal   
          isOpen={!!this.state.showEnlargedImg}
        >
          <ImageModalWrapper
            onClick={() => this.closeImgModal()}
          >
            {Close}
            {(!!this.props.images &&
              !!this.state.showEnlargedImg &&
              Number.isInteger(this.state.showImg)) ?
              (<React.Fragment>
                <img src={this.props.images[this.state.showImg].src} alt={this.props.images[this.state.showImg].description} style={{maxHeight: '82vh'}} />
                <CaptionText>{this.props.images[this.state.showImg].description}</CaptionText >
              </React.Fragment>) : null}
          </ImageModalWrapper>
        </ImageModal>

        <AwesomeSlider
          cssModule={AwsSliderStyles} 
        >
          {this.props && this.props.images && this.props.images.length > 0 ? (
            this.props.images.map((image, i) => {
              return (
                <div
                  data-src={image.src}
                  key={`key-image-${i}`}>
                  <Caption
                    bgColor={this.props.projectMainColor}
                    txtColor={this.props.txtColor}
                  >
                    <p>{image.description} <span
                    onClick={() => {
                      this.openImgModal(i)
                    }}
                    onKeyPress={(e) => {
                      console.log('keypress', e.key)
                    }}
                    role='button'
                    tabIndex='0'
                    ><ZoomIn txtColor={this.props.txtColor} /></span></p>
                  </Caption>
                </div>
              )
            })
          ): <p>no images to show, yet</p>}
        </AwesomeSlider>
      </Wrapper>
    )
  }
}
